@font-face {
    font-family: stardew;
    src: url("../../public/fonts/svthin.otf");
}

* { 
    margin: 0;
    
}

body 
{ 
    cursor: url('../../public/cursors/default.cur'), auto; 
}

.App
{
    height: 100dvh;
    width: 100dvw;

    background-position: 0;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    
    .bmc
    {
        position: absolute;
        top:0px;
        
    }
}

.menu
{
    width: 680px;
    height: 696px;
    background-repeat: no-repeat;

    position: relative;


    .borders
    {
        position: absolute;
        display: flex;
    }

    .red_border
    {

        background-image: url('../../public/menu/red_border.png');
        height: 64px;
        width: 64px;

        &.none
        {
            background-image: none;
        }
    }

    .red_border#male
    {
        position: relative;
        left: 80px;
        top: 248px;
    }
    .red_border#female
    {
        position: relative;
        left: 95px;
        top: 248px;
    }

    .stardew_fields
    {

        position: absolute;

        top: 44px;
        right: 132px;


        display: flex;
        flex-direction: column;

        .field
        {
            width: 172px;
            height: 36px;
            font-family: stardew;
            font-size: 24px;

			color: black;

            &:not(:first-child)
            {
                margin-top: 13px;
            }

            &:not(:last-child)
            {
                margin-bottom: 13px;
            }


            outline: none;
            border: none;

            background-color: #00000000;

            &:hover
            {
                background-color: #00000040;
				color: white;
            }

			&.animation {
					animation: onClick 1s 1;
			}
            
            &::selection
            {
                background-color: transparent;
            }

        }

    }


    .random
    {

        z-index: 9;

        position: absolute;
        top: 40px;
        left: 32px;

        height: 40px;
        width: 40px;

        background-color: #00000000;
        border: none;
        outline: none;

        background-repeat: no-repeat;
        background-size: cover;

        &:hover
        {
            height: 50px;
            width: 50px;
            
            top: 35px;
            left: 27px;


            cursor: url('../../public/cursors/select.cur'), pointer;



        }

    }

    
}

@keyframes onClick{
	0% {
			font-size: 24px;
	}
	50% {
			font-size: 50px;
	}
	100% {
			font-size: 24px;
	}
}

// Toasts

.toasts-container.none {
    display: none;
}

.toasts-container
{
    position: fixed;
    bottom: 80px;
    left: 25px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    z-index: 9;


    .toast
    {
        color: white;
        background-color: rgba($color: #2A2A2A61, $alpha: .4);

        backdrop-filter: blur(8px);

        border-radius: 10px;

        height: 75px;
        width: 250px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .toast--danger { background-color: rgba($color: #AA1A1A61, $alpha: .4) }
    .toast--success { background-color: rgba($color: #1aaa2161, $alpha: .4) }
    .toast--warning { background-color: rgba($color: #c4aa1761, $alpha: .4) }
}